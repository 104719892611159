<template>
  <div>
    <BaseModal
      :activeModal="show"
      @closeModal="close"
      :cancel="false"
      :ok="false"
      :close="true"
    >
      <template #header>
        Karta użytkownika
        <font-awesome-icon v-if="status.value === 0" icon="user-check" size="sm" class="mt-1 mr-1 text-green-400" title="aktywny"/>
        <font-awesome-icon v-else-if="status.value === 1" icon="user-lock" size="sm" class="mt-1 mr-1 text-yellow-500" title="zablokowany"/>
        <font-awesome-icon v-else icon="user-times" size="sm" class="mt-1 mr-1 text-red-400" title="wyłączony"/>
      </template>
      <template #default>
        <div v-if="isBusy" class="w-full p-4 h-full text-center">
          <font-awesome-icon class="text-6xl" icon="spinner" pulse/>
        </div>
        <div v-else class="flex flex-wrap">
          <div class="flex flex-col w-full sm:w-1/2 mb-2">
            <div class="relative w-full mr-0 sm:mr-3">
              <MicroMenu>
                <template #default>
                  <ul>
                    <li><a href="" @click.prevent="showEdit = true"><font-awesome-icon icon="edit" class="text-blue-500 mr-1" /> edytuj</a></li>
                    <template v-if="Number(user.blocked) === 0 && Number(user.disabled) === 0">
                      <li><a href="" @click.prevent="block(1)"><font-awesome-icon icon="user-lock" class="text-yellow-500 mr-1" /> zablokuj</a></li>
                      <li><a href="" @click.prevent="disable(1)"><font-awesome-icon icon="user-times" class="text-red-500 mr-1" /> wyłącz</a></li>
                    </template>
                    <template v-else-if="Number(user.blocked) === 1 && Number(user.disabled) === 0">
                      <li><a href="" @click.prevent="block(0)"><font-awesome-icon icon="user-check" class="text-green-500 mr-1" /> odblokuj</a></li>
                      <li><a href="" @click.prevent="disable(1)"><font-awesome-icon icon="user-times" class="text-red-500 mr-1" /> wyłącz</a></li>
                    </template>
                    <li v-else-if="Number(user.disabled) === 1"><a href="" @click.prevent="disable(0)"><font-awesome-icon icon="user-check" class="text-green-500 mr-1" /> włącz</a></li>
                  </ul>
                </template>
              </MicroMenu>
              <h1 class="text-xl font-semibold">Dane logowania</h1>
              <hr>
              <table class="mt-3">
                <tr>
                  <td class="font-semibold text-gray-500">login:</td>
                  <td>{{ user.name }}</td>
                </tr>
                <tr>
                  <td class="font-semibold text-gray-500">email:</td>
                  <td><a :href="`mailto:${user.email}`">{{ user.email }}</a></td>
                </tr>
                <tr>
                  <td class="font-semibold text-gray-500">status:</td>
                  <td>{{ status.name }}</td>
                </tr>
              </table>
              <button @click="showPass = true" class="mt-1 px-3 py-1 text-red-500 border border-red-500 hover:text-red-100 hover:bg-red-500 rounded font-semibold tracking-wide focus:outline-none">
                zmień hasło
              </button>
            </div>
          </div>
          <div class="flex w-full sm:w-1/2">
            <div class="relative w-full ml-0 sm:ml-3">
              <div @click="showEditPerson = true" class="absolute top-0 right-0 mt-1 px-2 mr-1 cursor-pointer text-blue-500 hover:text-blue-600" title="Edytuj">
                <font-awesome-icon icon="edit" />
              </div>
              <h1 class="text-xl font-semibold">Dane personalne</h1>
              <hr>
              <div class="mt-3">
                <p>{{ user.person.first_name }} {{ user.person.middle_name }} {{ user.person.surname }}</p>
                <p v-show="user.person.pesel_passport"><em class="text-gray-500">pesel:</em> {{user.person.pesel_passport}}</p>
                <p>
                  <span v-if="user.person.phone"><font-awesome-icon icon="phone" />&nbsp;
                    <a :href="`tel:${user.person.phone}`">
                      <span v-if="Number(user.person.regional_phone) === 1">{{ user.person.phone | phoneNo}}</span>
                      <span v-else>{{ user.person.phone }} <span class="text-xs text-gray-500"><em>zagraniczny</em></span></span>
                    </a><br>
                  </span>
                  <span v-if="user.person.email && user.person.email !== user.email"><font-awesome-icon icon="at" /> <a :href="`mailto:${user.person.email}`">{{ user.person.email }}</a><br></span>
                  <span v-if="user.person.address1">
                    {{ user.person.address1.street }} {{ user.person.address1.house_apt_no }}<br>
                    {{ user.person.address1.postal_code }} {{ user.person.address1.city }}<br>
                    <span class="text-sm text-gray-500"><em>region:</em></span> {{ user.person.address1.region ? user.person.address1.region.name : '' }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="flex w-full">
            <div class="w-full mr-0 sm:mr-3">
              <h1 class="text-lg font-semibold">Role w aplikacji</h1>
              <hr>
              <div class="mt-3 text-sm">
                <div class="flex">
                  <span class="font-semibold text-gray-600 mr-2"> Uprawnienia użytkownika:&nbsp;</span>
                  <div v-for="(role, index) in user.roles" :key="index">
                    <span v-if="index > 0">, </span>{{ role }}
                  </div>
                </div>
                <div v-if="user.person.employee" class="flex">
                  <span class="font-semibold text-gray-600 mr-2">
                    <router-link :to="{ name: 'employee', params: { id: user.person.employee.id }}">
                      Pracownik <font-awesome-icon icon="external-link-alt"/>
                    </router-link> :
                  </span>
                  <div v-for="(empRole, index) in user.employee_roles" :key="index">
                    <span v-if="index > 0">, </span>{{ empRole }}
                  </div>
                </div>
                <div v-if="user.contractors.length > 0" class="flex">
                  <span class="font-semibold text-gray-600 mr-3">
                    Osoba kontaktowa w:
                  </span>
                  <div v-for="(contractor, index) in user.contractors" :key="contractor.id">
                    <span v-if="index > 0">, </span>
                    <router-link :to="{ name: 'contractor', params: { id: contractor.id }}" class="text-sm font-semibold">
                      {{ contractor.company.shortname }} <font-awesome-icon icon="external-link-alt" />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
    <UserEdit
      v-if="showEdit"
      @close="showEdit = false"
      @changed="userChanged"
      :user="user"
    >
    </UserEdit>
    <UserChangePass
      v-if="showPass"
      @close="showPass = false"
      @changed="passChanged"
      :user="user"
    >
    </UserChangePass>
    <PersonEdit
      v-if="showEditPerson"
      @close="showEditPerson = false"
      @changed="personChanged"
      :person="user.person"
    >
    </PersonEdit>
  </div>
</template>

<script>
import moment from 'moment';
import UserService from '../services/UserService';
import MicroMenu from '../components/MicroMenu.vue';
import UserEdit from '../components/UserEdit.vue';
import UserChangePass from '../components/UserChangePass.vue';
import PersonEdit from '../components/PersonEdit.vue';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';

export default {
  name: 'UserItem',
  components: {
    MicroMenu,
    UserEdit,
    UserChangePass,
    PersonEdit,
  },
  mixins: [errorHandler, confirm],
  data() {
    return {
      show: false,
      showEdit: false,
      showPass: false,
      showEditPerson: false,
      isBusy: true,
      user: {
        person: {
          address1: {},
        },
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    status() {
      const status = { name: '', value: 0 };
      if (Number(this.user.blocked) === 0 && Number(this.user.disabled) === 0) {
        status.name = 'aktywny';
        status.value = 0;
      } else if (Number(this.user.disabled) === 1) {
        status.name = 'wyłączony';
        status.value = 2;
      } else if (Number(this.user.blocked) === 1) {
        status.name = 'zablokowany';
        status.value = 1;
      } else {
        return status;
      }
      return status;
    },
  },
  mounted() {
    this.show = true;
    this.getUser();
  },
  methods: {
    getUser() {
      UserService.getUser(this.id)
        .then(response => {
          this.setUserData(response.data.result);
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error); // method from mixin
        });
    },
    userChanged() {
      this.getUser();
      this.$store.dispatch('updates/userListNeedsUpdate');
    },
    passChanged() {
      // Tu komunikat o sukcesie
    },
    personChanged() {
      this.getUser();
      this.$store.dispatch('updates/userListNeedsUpdate');
    },
    block(val) {
      this.confirm(() => this.update({ blocked: val }), `Czy na pewno ${val === 0 ? 'odblokować' : 'zablokować'} użytkownika?`);
    },
    disable(val) {
      this.confirm(() => this.update({ disabled: val }), `Czy na pewno ${val === 0 ? 'włączyć ponownie' : 'wyłączyć'} konto użytkownika?`);
    },
    close() {
      const { query } = this.$route;
      this.$router.push({ name: 'users', query });
    },
    update(query) {
      // console.log(query);
      this.isBusy = true;
      UserService.updateUser(this.id, query)
        .then(response => {
          this.setUserData(response.data.result);
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
          this.$store.dispatch('updates/userListNeedsUpdate');
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    setUserData(data) {
      this.user = data;
      this.user.created_at = moment(this.user.created_at).format('YYYY-MM-DD');
      this.user.updated_at = this.user.updated_at ? moment(this.user.updated_at).format('YYYY-MM-DD') : null;
      if (this.user.person.address === null) {
        this.user.person.address = {};
      }
    },
  },
};
</script>
